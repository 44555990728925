// basics
$red : #FF473D;
$black: #383838;
$green: #11DA87;
$blue: #4AD5D5;
$yellow: #FFD426;
$white: #FFFFFF;
$white2: #F1F1F1;
$grey: #4F4F4F;
$grey2: #828282;
