@media (max-width: 1440px) {
  .news-main-block {
    background: $blue url("../../../assets/img/ship-main.png") no-repeat left -50px bottom -35px;
  }
  .about-title-block {
    background: $blue url(/assets/img/about-image.png) no-repeat right 200px top -50px;
  }
  .automation-title-block {
    background: $red url(/assets/img/hero-image.png) no-repeat right -100px top -60px;
  }
    .title-block {
    h1 {
      font-size: 42px;
      line-height: 50px;
    }
  }
  .main-title-block {
    background-position: right -30px top -100px;
    h1 {
      font-size: 42px;
      line-height: 50px;
    }
  }
  .phone-button {
    right: 10px;
  }
  .service-section {
    background-position: -100px 225px;
    .service-section-bg {
      background: $grey url(/assets/img/batiskaf.png) no-repeat right -10% top 140px;
    }
  }
  .news-title-block {
    background-position: 900px -100px;
  }
  .hr-title-block {
    background-position: 730px -50px;
  }
  .game-title-block {
    background-position: 800px -10px;
  }

  .packing-title-block {
    background-position: right -20px top -100px;
  }
  .inner-audit-text {
    max-width: 830px;
    width: 100%;
  }
  .ttl-title-block {
    background-position: 650px -50px;
  }
  .ambulance-section {
    .ambulance-text {
      width: 50%;
    }
    img {
      left: 55%
    }
  }
  .audit-title-block {
    background-position: right 100px top -60px;
  }
}

@media(max-width: 1100px) {

  .staff {
    .staff-block {
      flex-direction: column;
      .staff-item-img {
        margin-bottom: 30px;
      }
    }
  }

  .finfort-content-2 {
    height: unset;
    .finfort-note {
      flex-direction: column;
    }
  }

  #pagination-demo {
    margin-top: 30px;
  }
  .container {
    padding: 0 20px;
  }
  .phone-button {
    right: 20px;
  }
  .title-block {
    h1 {
      font-size: 36px;
      line-height: 50px;
    }
  }

  .services-blocks-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .services-block {
      &.big {
        max-width: 540px;
      }
      .background-image {
        &.img-5{
          top: -10%;
          right: -10%;
          width: 100%;
          height: 230%;
        }
      }
      h4 {
        font-size: 20px;
      }
    }
  }

  .news-list-title-block {
    background: #4AD5D5 url(/assets/img/news-image.png) no-repeat right -250px bottom 0;
    background-size: cover;
  }

  .news-list-section {
    .news-list-block {
      padding-top: 0;
      .news-right {
        display: none;
      }
      .news-left {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
    .news-btns {
      display: flex;
    }
  }
  .section-separator .section-separator-text {
    font-size: 28px;
  }
  .section-separator:before {
    width: 20px;
    height: 20px;
    bottom: -10px;
  }
  .neuro-title-block {
    background: $yellow url(/assets/img/neuro-image.png) no-repeat right -170px top -50px;
  }
  .service-section {
    padding-top: 0;
    background-position: -200px 205px;

    .circle-block .interior-circle p {
      font-size: 12px;
    }
  }
  .about-title-block {
    background: $blue url(/assets/img/about-image.png) no-repeat right 0 top -50px;
  }
  .main-partner {
    background-position: -260px 350px, 1400px 900px;

    .main-partner-text {
      img {
        max-width: 480px;
        height: 288px;
      }
    }
  }
  .ambulance-section {
    padding-top: 60px;
    .ambulance-title {
      font-size: 28px;
    }
    .ambulance-text {
      width: 50%;
      font-size: 15px;
    }
    img {
      left: 55%;
      width: 300px;
    }
  }
  .process-dnk-section {
    overflow: hidden;
    .dnk-image {
      right: -150px;
    }
  }
  .dnk-section .dnk-bg-image {
    width: 40%;
    left: -15%;
  }
}

@media(max-width: 800px) {
  .salesnight-results {

    img {
      display: none;
    }
    .salesnight-results-blocks {
      flex-wrap: wrap;
      .salesnight-results-block {
        height: unset;
        margin-bottom: 40px;
      }
    }
  }
  .salesnight-news {
    img {
      width: 100%;
      height: auto;
    }
  }
  .staff {
    padding: 60px 0;
    p {
      margin-left: 0;
    }
    .staff-block {
      .staff-item-img {
        width: 100%;
      }
    }
  }
  .carousel {
    width: 100%;
  }
  .news-main-block {
    display: none;
  }
  .title-block {
    .title-block-text {
      max-width: 480px;
    }
  }
  #pagination-demo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    font-size: 13px;

    li {
      margin: 0 6px;
      height: 25px;
      width: 25px;

      &.active {
        width: 25px;
      }
    }
  }
  header, footer {
    .logo {
      margin-top: 0;
      img {
        width: 173px;
        height: 26px;
      }
    }
    .main-nav nav {
      display: none;
    }
    .logo-social-title {
      display: block;
      text-decoration: none;
      color: #4F4F4F;
      font-size: 9px;
      line-height: 12px;
    }
    .social {
      .social-title {
        display: none;
      }
    }
  }
  header {
    height: 115px;
    .burger-menu {
      cursor: pointer;
      margin-top: 12px;
      display: block;
    }
  }
  .separator {
    width: 140px !important;
  }
  .phone-button {
    left: 20px;
    width: 65px;
    height: 65px;
  }
  .section-separator {
    height: 56px;
    .section-separator-text {
      font-size: 18px;
      line-height: 25px;
      padding-top: 15px;
    }
  }
  .service-section {
    background: $white2 url(/assets/img/ship-mob.png) no-repeat -80px 290px;
    .service-section-bg {
      background: $grey url(/assets/img/batiskaf-mob.png) no-repeat right 645px;
    }
  }
  .what-ttl-section-block {
    width: 100%;
    padding-right: 0;

    &:nth-child(1){
      margin-bottom: 18px;
    }
  }
  .ttl-area-section {
    .ttl-area-row {
      .ttl-area-block1 {
        flex-basis: 41.666%;
        width: unset;
      }
      .ttl-area-block2 {
        flex-basis: 24.21875%;
        width: unset;
      }
      .ttl-area-block3 {
        flex-basis: 34.11458%;
        width: unset;
      }
    }
  }


  .service-section-content {
    .first-column-content {
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
      p {
        font-size: 14px;
      }
    }
    .second-column-content {
      h5{
        margin-top: 14px;
      }
      a {
        font-size: 13px;
      }
    }
  }
  .title-block {
    height: 340px;
    h1 {
      font-size: 28px;
      line-height: 44px;
      max-width: 480px;
    }
    .phone-button {
      bottom: 60px;
    }
  }
  .neuro-data-section-block {
    width: 49%;
  }
  .main-title-block {
    background: $red url(/assets/img/aqamanant-mob.png) no-repeat right 120px;
    .title-block-text {
      max-width: 480px;
    }
  }
  .about-title-block {
    h1 {
      padding-top: 50px;
    }
    .phone-button {
      bottom: 13vw;
    }
  }
  .work-title-block {
    .phone-button {
      bottom: 8vw;
    }
  }
  .audit-deep-ocean-section {
    padding: 60px 0;
  }
  .audit-deep-ocean-img-lg {
    display: none;
  }
  .audit-deep-ocean-img-sm {
    display: block;
  }
  .packing-area-lg {
    display: none;
  }
  .packing-area-sm {
    display: block;
  }
  .packing-area-section {
    height: 420px;
  }
  .history {
    padding: 50px 0 30px;
  }
  .main-partner {
    width: 100%;
    font-size: 15px;
    background-position: -260px 20px, 1400px 900px;
    padding-bottom: 40px;
    .main-partner-title {
      display: none;
    }
    .main-partner-title-mob {
      display: block;
      text-align: right;
      margin: 0 20px;
    }
    p {
      margin: 10px 15px 15px 15px;
    }
  }
  .ambulance-section {
    padding-bottom: 210px;
    .ambulance-title {
    }
    .ambulance-text {
      width: 100%;
    }
    img {
      left: 0;
      bottom: -140px;
      width: 100%;
    }
  }
  .process-dnk-section {
    .dnk-image {
      right: -250px;
    }
  }
  .dnk-section {
    .dnk-section-content {
      padding: 35px 0 20px;
    }
    .dnk-section-content-img {
      display: none;
    }
    .dnk-section-content-text {
      flex-basis: 100%;
      .dnk-section-content-text-title {
        font-size: 13px;
        line-height: 20px;
      }
      p {
        font-size: 13px;
        line-height: 20px;
      }
    }
    .dnk-bg-image {
      display: none;
    }
  }
  .process-dnk-section {
    .process-dnk-section-blocks {
      padding-top: 50px;
    }
    .process-dnk-section-block {
      width: 100%;
      .process-dnk-section-block-number {
        flex-basis: 30px;
        img {
          width: 95%;
        }
      }
    }
    .process-dnk-section-block:first-child {
      .process-dnk-section-block-number:first-child {
        img {
          width: 52%;
        }
      }
    }
    .dnk-image {
      display: none;
    }
  }
  .audit-title-block {
    background-position: 410px -60px;
  }
  .packing-title-block {
    background-position: right -150px top -100px;
  }

  .modal {
    .modal-grey {
      padding: 40px 50px 40px;
      background: $grey;
    }
    .modal-grey2 {
      padding: 40px 50px 40px;
    }
    .modal-top-block {
      width: 320px;
    }
    .modal-top-block-text {
      padding: 0 20px;
    }
    .modal-light-grey {
      padding: 40px 50px 10px;
    }
    .modal-target {
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 15px;
      }
    }
  }

  .cookies {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(100% - 30px);
    height: 150px;
    padding: 10px 20px;

    .cookie-img {
      display: none;
    }
    .cookie-text {
      text-align: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      p {
        max-width: unset;
      }
    }
    .cookie-button {
      height: 45px;
      width: 160px;
      background: $green;
      border: 0;
      color: $white;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      outline: 0;
      cursor: pointer;
    }
  }
}

@media(max-width: 640px) {
  .circle-block {
    display: none;
  }
  .service-section-content {
    .second-column-content {
      .positioning-link {
        display: block;
      }
      a {
        margin-bottom: 17px;
      }
    }
  }
  .audit-title-block {
    background-position: 700px -60px;
  }

}

@media(max-width: 480px) {
  .salesnight-results {
    .salesnight-results-blocks {
      justify-content: center;
      .salesnight-results-block {
        max-width: 280px;
        width: 100%;
        .salesnight-results-block-title:after {
          width: 100%;
        }
      }
    }
  }
  .ttl-area-section {
    .ttl-area-row {
      flex-direction: column;
      height: unset;
      .ttl-area-block {
        height: 260px;
      }
    }
  }

  .portfolio-content {
    margin: 40px 0;

    .portfolio-content-img {
      margin: 40px 0;
    }
  }

  .asu-content {
    .content-block-image {
      margin: 40px 0;
    }
  }

  .webinar-slides {
    height: unset;

    .webinar-img {
      position: relative;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
    }

    .finfort-webinar-img-1 {
      right: 0;
    }
    .finfort-webinar-img-2 {
       top: 0;
     }
    .finfort-webinar-img-3 {
      top: 0;
      left: 0;
    }
    .finfort-webinar-img-4 {
      top: 0;
      right: 0;
    }
    .finfort-webinar-img-5 {
      top: 0;
      right: 0;
    }
    .finfort-webinar-img-6 {
      top: 0;
      left: 0;
    }
    .finfort-webinar-img-7 {
      top: 0;
      left: 0;
    }
    .finfort-webinar-img-8 {
      top: 0;
      left: 0;
    }
    .finfort-webinar-img-9 {
      top: 0;
      right: 0;
    }
    .finfort-webinar-img-10 {
      top: 0;
      right: 0;
    }
    .finfort-webinar-img-11 {
      top: 0;
      left: 0;
    }
    .finfort-webinar-img-12 {
      top: 0;
      left: 0;
    }
    .finfort-webinar-img-13 {
      top: 0;
      left: 0;
    }
  }
  .total {
    .total-inner {
      padding: 35px 0;

      .total-title {
        font-size: 24px;
      }

      .total-text {
        line-height: 24px;
      }

    }
  }

  .finfort-content-2 {
    height: unset;
    .finfort-note {
      flex-direction: column;
    }
  }

  .target {
    .target-inner {
      flex-direction: column;

      .target-inner-block {
        margin-bottom: 40px;
        &:nth-last-child(1){
          margin-bottom: 0;
        }

        .target-inner-block-site {
          position: relative;
        }
      }
    }
  }


  footer {
    .main-nav {
      padding-top: 40px;
      margin-bottom: 40px;
    }
    .social {
      flex-direction: column;
      .social-info {
        margin-top: 40px;
        flex-direction: row;
        width: 100%;
        margin-left: -20px;
        align-items: center;
        justify-content: space-between;
        height: 87px;
        border-top: 1px solid #F1F1F1;
        align-self: flex-start;
        padding: 30px 20px;
        .social-blank {
          margin-top: 0;
        }
        .social-icons {
          a {
            img {
              width: auto;
              height: 16px;
            }
          }
        }
        .social-info-text {
          display: none;
        }
        .social-info-text-mob {
          display: block;
          margin-top: 0;
          font-size: 13px;
          line-height: 22px;
        }
      }
    }
  }
  .title-block {
    .separator {
      margin: 25px 0;
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
      max-width: 298px;
    }
    .title-block-text {
      max-width: 180px;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 120px;
    }
  }
  .main-title-block {
    background: $red url(/assets/img/aqamanant-mob.png) no-repeat right -60px top 80px;
    .separator {
      margin: 30px 0;
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
      max-width: 298px;
    }
    .title-block-text {
      max-width: 235px;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 120px;
    }
  }
  .news-list-section {
    .news-block {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 30px 15px 20px;
      box-sizing: border-box;

      .news-block-image {
        height: 212px;
        div {
          h2 {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      .news-block-date {
        padding: 10px 0;
        font-size: 10px;
        line-height: 14px;
      }
      .news-block-text {
        font-size: 13px;
        line-height: 20px;
        padding: 0;
        height: unset;
      }
    }
    .news-list-block {
      .news-btns {
        width: 100%;
        button {
          width: 50%;
        }
      }
      .news-right {
        height: 290vw;
        padding: 50px 40px 30px;
        box-sizing: unset;

        h2 {
          font-size: 3.5vw;
          line-height: 5vw;
        }
        .news-block-text {
          font-size: 3.2vw;
          line-height: 4.5vw;
          max-height: 17vw;
        }
        .news-block {
          padding: 15px 0 0;
        }
        &:before {
          top: 14vw;
          left: 3vw;
          width: 20vw;
          height: 10vw;
          background: url('../../../assets/img/list-icon1.svg') center/contain no-repeat;
        }
        &:after {
          display: none;
        }
        .news-without-img:nth-child(6n+5){
          padding-top: 14vw;

          &:before {
            top: -5px;
            left: 20vw;
            width: 40vw;
            height: 20vw;
          }
        }
        .news-without-img:nth-child(6n+3){
          position: relative;
          padding-top: 14vw;

          &:before {
            top: -15px;
            right: -25px;
            width: 10vw;
            height: 15vw;
          }
        }
        .news-block-date {
          font-size: 3.2vw;
          line-height: 4.5vw;
          padding: 2vw 0;
          margin-top: 0;
        }
      }
    }

  }
  .news-list-title-block {
    background: $blue url(/assets/img/news-image-mob.png) no-repeat right -20px bottom -22px;
    .title-block-text {
      width: 100%;
      max-width: 100%;
    }
  }
  .service-section {
    height: 1010px;
    background-position: -40px 450px;
    .service-section-bg {
      height: 430px;
      background-position: 60px 280px;
    }
    .service-section-content {
      flex-direction: column;
      justify-content: start;
      padding-top: 45px;
      .first-column-content, .second-column-content {
        flex-basis: initial;
        height: auto;
        margin-bottom: 10px;
        h2 {
          font-size: 24px;
          line-height: 32px;
        }
        a {
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 17px;
        }
      }
      .first-column-content {
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .second-column-content {
        p {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  .section-separator {
    .section-separator-text {
      font-size: 18px;
      line-height: 25px;
      padding-top: 15px;
    }
  }
  .section-separator.one-line {
    height: 56px;
  }
  .brends-section {
    padding-top: 10px;
    .brends {
      height: 195px;
      img {
        margin: 13px 5px;
        height: 140px;
      }
      .brend-1 {
        width: 120px;
      }
      .brend-2 {
        width: 151px;
      }
      .brend-3 {
        width: 67px;
      }
      .brend-4 {
        width: 100px;
      }
      .brend-5 {
        width: 73px;
      }
      .brend-6 {
        width: 50px;
      }
      .brend-7 {
        width: 144px;
      }
      .brend-8 {
        width: 53px;
      }
      .brend-9 {
        width: 123px;
      }
      .brend-10 {
        width: 116px;
      }
      .brend-11 {
        width: 43px;
      }
      .brend-12 {
        width: 114px;
      }
      .brend-13 {
        width: 37px;
      }
      .brend-14 {
        width: 94px;
      }
      .brend-15 {
        width: 117px;
      }
      .brend-16 {
        width: 173px;
      }
    }
  }
  .history {
    p {
      font-size: 13px;
      line-height: 20px;
    }
  }
  .main-partner {
    background: none;
    padding-top: 0;
    padding-bottom: 15px;
    font-size: 13px;
    line-height: 20px;
    .main-partner-text {
      img {
        width: 100vw;
        height: unset;
      }
    }
    .main-partner-text .italic {
      position: relative;
      text-align: center;
      margin: 30px 0;

      &:after {
        position: absolute;
        content: '';
        bottom: -58px;
        right: -38px;
        width: 100px;
        height: 84px;
        background: url(/assets/img/about-circles2.svg) center/contain no-repeat;
      }
      &:before {
        position: absolute;
        content: '';
        top: -95px;
        left: -30px;
        width: 94px;
        height: 84px;
        background: url(/assets/img/about-circles1.svg) center/contain no-repeat;
      }
    }
    .main-partner-title-mob {
      font-size: 10px;
      line-height: 14px;
      margin-top: 8px;
    }
  }
  .about-title-block {
    height: 70vw;
    background: $blue url(/assets/img/about-mob.png) no-repeat right 0 top 70px;
  }
  .neuro-title-block {
    height: 100vw;
    background: $yellow url(/assets/img/neuro-image-mob.png) no-repeat right 0 top 27vw;
    .phone-button {
      bottom: 45vw;
    }
  }
  .automation-title-block {
    background: $red url(/assets/img/hero-img.png) no-repeat right -60px top 36vw;
  }
  .why-automation-section {
    padding: 30px 0 40px;
    .quote{
      display: flex;
      flex-direction: column;
      .author {
        margin-bottom: 15px;
      }
    }
  }
  .how-automation-section-blocks {
    flex-direction: column;
    .how-automation-section-block {
      width: 100%;
      padding-right: 0;
    }
  }
  .positioning-title-block {
    background: $yellow url(/assets/img/positioning-mob.png) no-repeat right -2vh bottom -14vw;
    .title-block-text {
      max-width: 280px;
    }
    .phone-button {
      bottom: 6vw;
    }
  }
  .positioning-about-section {
    padding: 30px 0 50px;
    background: $white;
    .positioning-title {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
  .positioning-theory-section {
    padding: 50px 0;
    .positioning-text {
      margin-top: 35px;
    }
    .marketing-mix {
      flex-direction: column;
      .marketing-mix-block {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  .positioning-theory2-section {
    padding: 30px 0;
    background: $white;
    p {
      font-size: 14px;
      line-height: 22px;
    }
    .positioning-title-blue {
      font-size: 14px;
      margin: 20px 0 15px;
    }
  }
  .packing-title-block {
    height: 100vw;
    background: $green url(/assets/img/packing-image-mob.png) no-repeat right 0 top 15vw;
    .phone-button {
      bottom: 15vw;
    }
  }
  .audit-title-block {
    height: 110vw;
    background: $blue url(/assets/img/audit-image-mob.png) no-repeat right 0 top 29vw;
    .phone-button {
      bottom: 30vw;
    }
  }
  .ttl-title-block {
    height: 100vw;
    background: $blue url(/assets/img/ttl-image-mob.png) no-repeat right -130px top 29vw;
    .phone-button {
      bottom: 7vw;
    }
  }
  .form-section {
    margin: 50px 0;
    .circle-1 {
      display: none;
    }
    .circle-2 {
      display: none;
    }
    .circle-3 {
      display: none;
    }
    .circle-4 {
      display: none;
    }
    form {
      margin: 0;
    }
  }
  .inner-audit-title-block {
    height: 100vw;
    background: $white2 url(/assets/img/training-img-mob.png) no-repeat right 0 bottom 0;
    .phone-button {
      bottom: 5vw;
    }
  }
  .inner-audit-section {
    padding: 32px 0;
    .inner-audit-text {
      font-size: 13px;
      line-height: 20px;
    }
  }
  .what-ttl-section {
    padding: 35px 0;
    .what-ttl-text {
      font-size: 13px;
      line-height: 26px;
    }
    .what-ttl-text-yellow {
      margin-top: 50px;
      font-size: 13px;
      line-height: 20px;
    }
  }
  .what-ttl-section-blocks {
    padding-top: 40px;
  }

  .what-ttl-section-block-text {
    .what-ttl-section-block-title {
      padding-left: 50px;
      font-size: 13px;
      line-height: 20px;
      &:before {
        top: 5px;
        width: 30px;
        height: 30px;
      }
    }
    .what-ttl-section-block-download {
      padding-left: 50px;
      font-size: 13px;
      line-height: 18px;
    }
    .what-ttl-section-block-subtext {
      padding-left: 50px;
      font-size: 13px;
      line-height: 20px;
    }
  }
  .audit-result-section {
    font-size: 13px;
    line-height: 20px;
    .audit-result-section-blocks {
      padding-top: 70px;
    }
    .audit-result-section-block {
      width: 100%;
      padding-right: 0;
      img {
        height: 60px;
        max-width: 35px;
      }
      .audit-result-section-block-number {
        flex-basis: 35px;
      }
      .audit-result-section-block-text {
        flex-basis: calc(100% - 35px - 20px);
        .audit-result-section-block-title {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
  .metodology-section {
    padding: 60px 0 80px;
    .metodology-section-text {
      margin: 20px 0;
    }
  }
  .why-packing-section {
    padding: 35px 0;
    .why-packing-text {
      font-size: 13px;
      line-height: 26px;
    }
    img {
      display: none;
    }
  }
  .packing-cases-section {
    padding: 35px 0;
    .packing-cases-section-blocks {
      padding-top: 25px;
    }
    .packing-cases-section-block {
      width: 100%;
      margin-bottom: 25px;
    }
    .packing-cases-section-text {
      text-align: left;
    }
    .packing-cases-section-block-text {
      .packing-cases-section-block-title {
        font-size: 13px;
        line-height: 20px;
      }
      .packing-cases-section-block-t {
        font-size: 13px;
        line-height: 20px;
      }
      .packing-cases-section-block-t-sm {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .what-neuro-section {
    padding: 30px 0;
    .why-neuro-text {
      font-size: 13px;
      line-height: 26px;
    }
  }
  .neuro-data-section-block-t-lg {
    margin-top: 60px;
    font-size: 14px;
    line-height: 22px;
  }
  .neuro-data-section-blocks {
    padding-top: 25px;
  }
  .neuro-data-section-block {
    width: 50%;
    padding-right: 0;
  }
  .neuro-data-section-block-text {
    .neuro-data-section-block-title {
      font-size: 13px;
      line-height: 20px;
    }
    .neuro-data-section-block-t {
      margin-top: 5px;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .services-title-block {
    height: 265px;
    background: $red url(/assets/img/services-mob.png) no-repeat right 0 top 0;
    background-size: auto 100%;

    .phone-button {
      bottom: 45px;
    }
  }
  .services-blocks-section {
    padding: 30px 20px;
    box-sizing: border-box;
    align-items: unset;

    .services-block {
      width: 100%;
      height: 315px;
      padding: 20px;

      &.big {
        padding-top: 25px;
      }

      .small-text {
        font-size: 12px;
        line-height: 16px;
      }

      .small-text2 {
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        margin: 12px 0 0;
      }

      h3 {
        font-size: 14px;
        line-height: 20px;
      }

      h4 {
        font-size: 18px;
        line-height: 25px;
        padding-top: 16px;
        color: $black;
      }

      .background-image {

        &.img-1 {
          top: 52%;
          right: 0;
          width: 100%;
          height: 130%;
        }
        &.img-2 {
          top: 8%;
          right: -25%;
          width: 140%;
          height: 130%;
        }
        &.img-3 {
          bottom: 42%;
          left: 0;
          width: 120%;
          height: 120%;
        }
        &.img-4 {
          bottom: 0;
          right: -15%;
          width: 70%;
          height: 70%;
        }
        &.img-5 {
          top: 22%;
          right: -17%;
          width: 100%;
          height: 260%;
        }
        &.img-6 {
          bottom: -22%;
          right: -7%;
          width: 100%;
          height: 100%;
        }
        &.img-7 {
          bottom: -20%;
          right: 0;
          width: 100%;
          height: 100%;
        }

      }
    }
  }
  .work-format-section {
    font-size: 12px;
    line-height: 16px;
    .work-format-section-block {
      width: 100%;
      padding-right: 0;
      margin-bottom: 40px;
    }
    .work-format-section-blocks {
      padding-top: 50px;
    }
    .work-format-section-block-text {
      flex-basis: 100%;
      padding-left: 0;

      .work-format-section-block-title {
        font-size: 14px;
        line-height: 22px;
      }
      .work-format-section-block-subtitle {
        font-size: 11px;
        line-height: 20px;
      }
    }
  }
  .work-title-block {
    height: 110vw;
    background: $green url(/assets/img/howwework-mob.png) no-repeat right -40px top 30vw;
  }

  .work-title-text-lg {
    display: none;
  }
  .work-title-text-sm {
    display: block;
  }
  .ambulance-section {
    padding-top: 40px;
    .ambulance-title {
      font-size: 24px;
      line-height: 25px;
      margin-bottom: 20px;
    }
    .ambulance-text {
      font-size: 13px;
      line-height: 20px;
      span {
        display: inline-block;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }
  }
  .process-dnk-section {
    font-size: 13px;
    line-height: 20px;
    .process-dnk-section-block {
      .process-dnk-section-block-text {
        flex-basis: calc(100% - 30px - 20px);
        .process-dnk-section-block-title {
          margin-top: 0;
        }
      }
    }
  }
  header {
    .close-nav {
      right: 20px;
    }
  }

  .cost-section {
    .cost-block {
      flex-direction: column;
      .cost-title {
        align-self: center;
        text-align: center;
        margin-top: 10px;
        font-size: 24px;
        line-height: 32px;
      }
      .cost-text {
        margin-top: 20px;
        margin-bottom: 15px;
        font-size: 13px;
        line-height: 20px;
      }

    }
  }

  .modal {
    .toggle-person {
      width: 100%;
      left: 0;
      top: 0;
      max-height: 100vh;
    }
    .modal-grey {
      padding: 40px 20px 60px;
    }
    .modal-grey2 {
      padding: 40px 20px 40px;
    }
    .modal-top-block {
      width: 100%;

      button {
        display: none;
      }
    }
    .modal-top-block-text {
      padding: 0;
    }
    .modal-light-grey {
      padding: 30px 20px 10px;

      .modal-target {
        margin-bottom: 32px;
      }
    }
    .modal-text-with-icon {
      img {
        width: 36px;
      }
      p {
        margin: 3px 0 2px 20px;
        font-size: 18px;
        line-height: 25px;
      }
      span {
        margin-left: 20px;
        font-size: 14px;
        line-height: 22px;
        display: block;
      }
      .little-span {
        font-size: 12px;
        line-height: 16px;
        margin-top: 11px;
      }
    }
    .modal-target {

      span {
        font-size: 18px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

@media(max-width: 411px) {
  .section-separator.two-line {
    height: 80px;
  }
}
@media(max-width: 359px) {
  .container {
    padding: 0 10px;
  }
  .title-block-text {
    max-width: 220px;
  }
  .phone-button {
    left: 10px;
  }
  .main-partner {
    .main-partner-text {
      img {
        max-width: 320px;
        height: 192px;
      }
    }
  }
  header {
    .close-nav {
      right: 13px;
      top: 25px;
    }
  }
}

@media(min-width: 801px) {
  .audit-deep-ocean-img-sm {
    display: none;
  }
  .packing-area-sm {
    display: none;
  }
}

@media(min-width: 481px) {
  .work-title-text-sm {
    display: none;
  }
}
