@import "/app/assets/css/variables/colors.scss";


body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: $black;
  font-size: 16px;
  line-height: 26px;
}

p, h1 {
  margin: 0;
}

a {
  cursor: pointer;
}

button {
  -webkit-appearance: none;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

.container {
  margin: 0 auto;
  max-width: 1100px;
  height: 100%;
  position: relative;
}

.title-block {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 390px;
  z-index: 1;
  h1 {
    font-size: 50px;
    line-height: 61px;
    color: $white;
    font-weight: 700;
    margin: 0;
    padding-top: 60px;
    width: 850px;
  }
  .separator {
    width: 270px;
    height: 8px;
    margin: 30px 0;
  }
  .title-block-text {
    width: 560px;
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 80px;
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2;
    background: url(../img/dot.png) center/contain repeat;
  }
}

.phone-button {
  width: 65px;
  height: 65px;
  position: absolute;
  bottom: 30px;
  right: 0;
  z-index: 999;
}

.yellow-phone {
  background: url(/assets/img/yellow-phone.svg) center/contain no-repeat;
}

.red-phone {
  background: url(/assets/img/red-phone.svg) center/contain no-repeat;
}

.blue-phone {
  background: url(/assets/img/blue-phone.svg) center/contain no-repeat;
}

.breadcrumbs {
  position: relative;
  z-index: 999;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;

  .breadcrumbs-item {
    display: inline-block;

    &:first-of-type::after {
      content: '/';
      margin: 0 7px;
      color: $white;
    }

    &:not(:last-of-type)::after {
      content: '/';
      margin: 0 7px;
      color: $white;
    }

    .breadcrumbs-link {
      color: $white;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
    .breadcrumbs-link-active {
      color: $white;
      text-decoration: none;
      cursor: default;
    }
  }
}

.section-separator {
  width: 100%;
  height: 100px;
  color: $white;
  position: relative;
  &:before {
    z-index: 1;
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    transform: rotate(45deg);
    bottom: -18px;
  }
  .section-separator-text {
    font-size: 32px;
    line-height: 100px;
    color: $white;
    font-weight: 700;
    margin: 0;
  }
}

.green-separator {
  background-color: $green;
  &:before {
    background-color: $green;
  }
}

.blue-separator {
  background-color: $blue;
  &:before {
    background-color: $blue;
  }
}

.yellow-separator {
  background-color: $yellow;
  &:before {
    background-color: $yellow;
  }
}

.black-separator {
  background-color: $black;
  &:before {
    background-color: $black;
  }
}

.grey-separator {
  background-color: $grey;
  &:before {
    background-color: $grey;
  }
}

.half {
  &:before {
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0) rotate(45deg);
  }
}

.fourth {
  &:before {
    left: 25%;
    margin-right: -25%;
    transform: translate(-25%, 0) rotate(45deg);
  }
}

.third {
  &:before {
    left: 33%;
    margin-right: -33%;
    transform: translate(-33%, 0) rotate(45deg);
  }
}

.fourth-back {
  &:before {
    left: 75%;
    margin-right: -75%;
    transform: translate(-75%, 0) rotate(45deg);
  }
}

.yellow-text {
  color: $yellow !important
}

.yellow-text-ttl {
  color: $black !important;
  background: $yellow;
  display: inline-block !important;
}

.white-text {
  color: $white
}

.black-text {
  color: $black
}

.red-bg {
  background-color: $red;
}

.yellow-bg {
  background-color: $yellow;
}

.grey-bg {
  background-color: $grey;
}

.green-bg {
  background-color: $green;
}

.blue-bg {
  background-color: $blue;
}

.white-bg {
  background-color: $white2;
}

@mixin posAbs($top,$left) {
  position: absolute;
  top: $top;
  left: $left;
}

.circle-block {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1.2%;
  z-index: 3;
  transition: .2s ease-in-out;
  .interior-circle {
    border-radius: 50%;
    z-index: 4;
    width: 100%;
    padding-top: calc(50% - 13px);
    p {
      font-weight: 700;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: calc(50% - 13px);
      z-index: 5;
      margin: 0;
    }
  }
  .interior-circle-yellow {
    background-color: $yellow;
  }
  .interior-circle-blue {
    background-color: $blue;
  }
  .interior-circle-red {
    background-color: $red;
  }
  .interior-circle-green {
    background-color: $green;
  }
  .interior-circle-white {
    background-color: $white;
  }

  &:hover {
    padding: 2%;
  }
}

.cost-section {
  background-color: $yellow;
  .cost-block {
    font-weight: 700;
    display: flex;
    display: -webkit-flex;
    .cost-title {
      width: 220px;
      color: $white;
      font-size: 32px;
      line-height: 75px;
      align-self: center;
    }
    .cost-text {
      margin: 35px 0 30px;
      flex-basis: 60%;
      color: $black;
      font-size: 15px;
      line-height: 23px;
      align-self: center;
    }
  }
}

/* component header */
header {
  width: 100%;
  height: 144px;
  .container {
    height: 100px;
  }
  .header-bg {
    height: 44px;
    width: 100%;
    background-color: $black;
  }
  #show-menu {
    box-sizing: border-box;
    position: fixed;
    z-index: 9999;
    top: 43px;
    bottom: 0;
    width: 100vw;
    height: calc(100vh - 43px);
    transition: 0.6s;
    left: -100vw;
    opacity: 0;
    background-color: $white;
    padding: 45px 0;
    nav {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      margin-bottom: 40px;
      a {
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 25px;
        color: $black;
        padding-left: 4px;
        border-left: 65px solid $white;
      }
    }
    .bottom-nav-block {
      width: 100%;
      border-top: 9px solid $yellow;
      padding: 33px 0 40px;
      position: absolute;
      bottom: 0;
      .logo {
        margin-left: 20px;
      }
      .social-adress-block {
        margin-left: 20px;
        margin-top: 33px;
        font-size: 16px;
        line-height: 22px;
        .social-adress {
          margin-bottom: 23px;
        }
        .social-email {
          font-weight: 600;
        }
      }
    }
  }
  .close-nav {
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 20px;
    width: 21px;
    height: 25px;
    background: url("/assets/img/close-nav-icon.svg") center/cover no-repeat;
  }
  .social {
    height: 44px;
    color: $white;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;

    .tel {
      margin-right: 10px;
      padding-top: 8px;
    }

    .social-info {
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -webkit-align-self: center;
      align-self: center;
      a {
        text-decoration: none;
        margin-left: 30px;
        img {
          margin-top: 4px;
          transition: all .15s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    //.social-info {
    //  display: flex;
    //  display: -webkit-flex;
    //  justify-content: space-between;
    //  -webkit-justify-content: space-between;
    //  -webkit-align-self: center;
    //  align-self: center;
    //  .social-icons {
    //    width: 100px;
    //    display: flex;
    //    display: -webkit-flex;
    //    justify-content: space-between;
    //    -webkit-justify-content: space-between;
    //    -webkit-align-self: center;
    //    align-self: center;
    //  }
    //  .tel {
    //    margin-left: 35px;
    //  }
    //  a {
    //    text-decoration: none;
    //    img {
    //      margin-top: 5px;
    //    }
    //  }
    //}
  }
  .main-nav {
    margin-top: 12px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    .logo-social-title {
      -webkit-align-self: center;
      align-self: center;
      font-size: 11px;
      line-height: 19px;
      font-weight: 400;
    }
    nav {
      width: 650px;
      margin-top: 28px;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -webkit-align-self: flex-end;
      align-self: flex-end;
      margin-bottom: 3px;
      margin-left: 15px;
      a {
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: $black;
        padding-bottom: 4px;
      }
    }
  }
  .logo {
    width: 200px;
    height: 30px;
    margin-top: 11px;
    text-decoration: none;
  }
  .burger-menu {
    display: none;
  }
}

/* index.html */

.main-title-block {
  background: $red url(/assets/img/aqamanant.png) no-repeat right 5% top -100px;
  h1 {
    padding-top: 50px;
  }
  .separator {
    background-color: yellow;
  }
}

.service-section {
  padding-top: 20px;
  position: relative;
  width: 100%;
  height: 760px;
  background: $white2 url(/assets/img/ship.png) no-repeat left 15% top 230px;
  .circle-1 {
    top: 45%;
    left: 60%
  }
  .circle-2 {
    top: 43%;
    left: 72%
  }
  .circle-3 {
    top: 45%;
    left: 74%;
    a {
      text-decoration: none;
    }
    &:hover {
      top: 43.7%;
      left: 73.2%;
    }
  }
  .service-section-bg {
    position: absolute;
    bottom: 0;
    height: 329px;
    width: 100%;
    background: $grey url(/assets/img/batiskaf.png) no-repeat right 6% top 140px;
    .service-section-content {
      color: $white;
    }
  }
}

.service-section-content {
  display: flex;
  display: -webkit-flex;
  align-items: start;
  font-weight: 600;
  padding-top: 50px;
  .first-column-content {
    flex-basis: 50%;
    h2 {
      font-size: 30px;
      line-height: 65px;
      margin: 0;
    }
    p {
      margin-top: 0;
      font-size: 20px;
      line-height: 49px;
      font-weight: 400;
      text-decoration: none;
    }
    .yellow-text {
      text-decoration: none;
    }
  }
  .second-column-content {
    flex-basis: 470px;
    .second-column-block {
      transition: all .2s ease-in-out;
      text-decoration: none;
      display: block;
      color: $black;
      &:hover {
        padding-left: 25px;
        text-decoration: underline;
      }
    }
    h5 {
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      margin: 0;
      margin-top: 20px;
      color: $black;
      display: block;
    }
    p {
      font-size: 13px;
      font-weight: normal;
      line-height: 20px;
      margin-top: 4px;
    }
    .positioning-link {
      display: none;
    }
  }
  .white-links {
    h5 {
      color: $white;
    }
    .second-column-block {
      color: $white;
    }
  }
}

.brends-section {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  background: $yellow;
  overflow-x: hidden;
  .brends {
    height: 233px;
    width: 7425px;
    margin-top: 20px;
    .first {
      -webkit-animation: bannermove 30s linear infinite;
      -moz-animation: bannermove 30s linear infinite;
      -ms-animation: bannermove 30s linear infinite;
      -o-animation: bannermove 30s linear infinite;
      animation: bannermove 30s linear infinite;
    }
  }
}

/* about */

.about-title-block {
  height: 390px;
  background: $blue url(/assets/img/about-image.png) no-repeat right 400px top -50px;
  .separator {
    background-color: yellow;
    width: 144px;
  }
}

.history {
  background-color: $white2;
  padding: 80px 0px;
  .history-title {
    display: inline;
    background: $yellow;
    color: $black;
    font-size: 15px;
    line-height: 23px;
    font-weight: 700;
  }
  p {
    width: 95%;
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    color: $black;
    margin-bottom: 15px;
    margin-top: 15px;
    span {
      font-weight: 700;
    }
  }
}

.main-partner {
  padding-top: 80px;
  padding-bottom: 180px;
  width: 95%;
  color: $black;
  font-size: 16px;
  line-height: 23px;
  background-image: url(/assets/img/circles-img1.svg), url(/assets/img/circles-img2.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: 0px 350px, 1400px 900px;
  .main-partner-content {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
  .main-partner-title, .main-partner-title-mob {
    flex-basis: 230px;
    font-weight: 700;
  }
  .main-partner-title-mob {
    display: none;
  }
  .main-partner-text {
    flex-basis: calc(86% - 230px);
    font-weight: 400;
    p {
      margin-bottom: 15px;
    }
    img {
      width: 720px;
      height: 433px;
    }
  }
  .main-partner-text .italic {
    font-style: italic;
    margin-top: 10px;
  }
}

/* work */

.work-title-block {
  height: 420px;
  background: $green url(/assets/img/howwework.png) no-repeat right 16% top -55px;
  .separator {
    background-color: yellow;
    width: 270px;
  }
  .title-block-text {
    width: 720px;
  }
  .work-title-text-sm {
    width: 70% !important;
    max-width: unset !important;
  }
}

.dnk-section {
  position: relative;
  background-color: $white2;
  height: 100%;
  .dnk-bg-image {
    position: absolute;
    bottom: -40px;
    left: -5%;
    width: 30%;
    img {
      width: 100%;
    }
  }
  .dnk-section-content {
    padding: 80px 0;
    font-size: 16px;
    line-height: 23px;
    color: $black;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
  .dnk-section-content-img {
    width: 25%;
    flex-basis: 25%;
    img {
      width: 100%;
    }
    .dnk-section-content-img-title {
      font-weight: 700;
    }
  }
  .dnk-section-content-text {
    flex-basis: 75%;
    p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 15px;

    }
    .dnk-section-content-text-title {
      display: inline-block;
      font-size: 20px;
      line-height: 26px;
      background-color: $green;
      font-weight: 700;
      color: $white;
    }
  }
}

.work-format-section {
  background-color: $grey;
  font-size: 16px;
  line-height: 26px;
  color: $white;
  .work-format-section-bg {
    position: relative;
    height: 100%;
    width: 100%;
    bottom: 0;
  }
  .work-format-section-blocks {
    padding-top: 100px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .work-format-section-block {
    width: 47%;
    padding-right: 3%;
    margin-bottom: 80px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
  }
  .work-format-section-block-text {
    flex-basis: calc(100% - 69px - 20px);
    padding-left: 20px;
    .work-format-section-block-title {
      color: $black;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      display: inline-block;
      background-color: $blue;
    }
    .work-format-section-block-subtitle {
      color: $white;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
    }
    .work-format-section-block-more {
      display: inline-block;
      font-weight: 700;
      font-size: 15px;
      line-height: 29px;
      text-decoration-line: underline;
      color: $yellow;
      cursor: pointer;

      &:hover {
        text-decoration-line: none;
      }
    }
  }
}

.modal {
  .modal-grey {
    padding: 80px 100px 50px;
    background: $grey;
  }
  .modal-grey2 {
    padding: 50px 100px 50px;
    background: $grey;

    .modal-target {
      color: $white;
    }
  }
  .modal-light-grey {
    padding: 50px 100px 10px;
    background: $white2;

    .modal-target {
      margin-bottom: 50px;
    }
  }
  .modal-top-block {
    width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 10px;
      height: 17px;
      background: url("/assets/img/arrow-prev.svg") center/contain no-repeat;
      border: 0;
      margin-top: 20px;
    }
    .modal-top-block-next {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  .modal-top-block-text {
    padding: 0 60px;
    .modal-top-block-title {
      color: $black;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      display: inline-block;
      background-color: $blue;
    }
    .modal-top-block-subtitle {
      color: $white;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
    }
    .modal-top-block-more {
      font-weight: 700;
      font-size: 15px;
      line-height: 29px;
      text-decoration-line: underline;
      color: $yellow;
    }
  }
  .modal-top-text {
    color: $white;
    font-size: 16px;
    line-height: 26px;
  }
  .modal-text-with-icon {
    color: $white;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 40px;

    .little-span {
      display: block;
      font-size: 13px;
      line-height: 20px;
      margin-top: 11px;
    }
    p {
      margin: 3px 0 4px 30px;
      font-weight: bold;
      font-size: 26px;
      line-height: 23px;
    }

    span {
      margin-left: 30px;
      font-size: 16px;
      line-height: 26px;
    }
  }
  .modal-target {
    display: flex;
    flex-direction: row;

    span {
      font-weight: 700;
      font-size: 20px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 22%;
    }

    .modal-target-bold {
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
    }
  }
}

.process-dnk-section {
  background-color: $grey;
  font-size: 16px;
  line-height: 26px;
  color: $white;
  .process-dnk-section-bg {
    position: relative;
    height: 100%;
    width: 100%;
    bottom: 0;
  }
  .dnk-image {
    position: absolute;
    bottom: -10px;
    right: 0;
  }
  .process-dnk-section-blocks {
    padding-top: 80px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .process-dnk-section-block {
    width: 47%;
    padding-right: 3%;
    margin-bottom: 60px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    .process-dnk-section-block-number {
      flex-basis: 69px;
      display: -webkit-flex;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

    }
    .process-dnk-section-block-text {
      flex-basis: calc(100% - 69px - 20px);
      padding-left: 20px;
      .process-dnk-section-block-title {
        margin: 3px 0;
        color: $black;
        font-weight: 700;
        display: inline-block;
        background-color: $yellow;
      }
    }
  }
  .eight-block {
    width: 70%;
    .process-dnk-section-block-text {
      .process-dnk-section-block-title {
        background-color: $green;
      }
    }
  }
}

.ambulance-section {
  background-color: $red;
  padding: 80px 0;
  color: $white;
  .ambulance-title {
    margin: 0 0 32px;
    font-size: 32px;
  }
  .ambulance-text {
    font-size: 15px;
    line-height: 23px;
    width: 70%;
    margin-bottom: 0;
    span {
      font-weight: 700;
    }
  }
  img {
    position: absolute;
    bottom: 0;
    left: 70%
  }
}

/* news-list */

.news-list-title-block-bg, .news-title-block-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.news-list-title-block {
  background: $blue url(/assets/img/news-image.png) no-repeat right -150px bottom 0;
  background-size: cover;
  .title-block-text {
    max-width: 560px;
    width: 100%;
  }
  h1 {
    padding-top: 60px;
  }
  .separator {
    background-color: $red;
  }
}

.news-list-section {
  width: 100%;
  height: 100%;
  .news-btns {
    padding-top: 45px;
    display: flex;
    justify-content: center;
    button {
      width: 160px;
      height: 35px;
      background: $white;
      color: $red;
      border: 1px solid #FF473D;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      outline: 0;

      &:nth-child(1){
        border-radius: 14px 0px 0px 14px;
      }
      &:nth-child(2){
        border-radius: 0 14px 14px 0px;
      }
      &.active {
        background: $red;
        color: $white;
      }
    }
  }
  .news-list-section-bg {
    position: absolute;
    width: 100%;
    height: 12px;
    background-color: $yellow;
  }
  .news-btns {
    display: none;
  }
  .news-list-block {
    padding-top: 50px;
    display: flex;
    flex-flow: row;
    justify-content: center;

    .news-left {
      display: -webkit-flex;
      display: flex;
      flex-flow: column;
      align-content: center;
      box-sizing: border-box;
    }

    .news-right {
      position: relative;
      width: 557px;
      height: 1500px;
      display: -webkit-flex;
      display: flex;
      flex-flow: column;
      align-content: center;
      box-sizing: border-box;
      font-family: 'Anonymous Pro', sans-serif;
      background: url("../../assets/img/list.svg") top/contain no-repeat;
      padding: 100px 60px 30px;
      box-sizing: border-box;

      &:before {
        position: absolute;
        content: '';
        top: 65px;
        left: 43px;
        width: 180px;
        height: 80px;
        background: url('../../assets/img/list-icon1.svg') center/contain no-repeat;
      }
      &:after {
        position: absolute;
        content: '';
        bottom: 37px;
        right: 46px;
        width: 100px;
        height: 100px;
        background: url('../../assets/img/list-icon4.svg') right/contain no-repeat;
      }

      h2 {
        font-size: 20px;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
        }
      }
      .news-block-text {
        font-size: 18px;
        line-height: 24px;
        height: unset;
        max-height: 100px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 0;
      }
      .news-block {
        height: unset;
        width: unset;
        padding: 20px 0 0;
        border-bottom: 0;
      }
      .news-block-date {
        display: block;
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        text-align: right;
        color: $black;
      }

      .news-without-img:nth-child(6n+5){
        position: relative;
        padding-top: 100px;

        &:before {
          position: absolute;
          content: '';
          top: 10px;
          left: 90px;
          width: 230px;
          height: 90px;
          background: url('../../assets/img/list-icon2.svg') center/contain no-repeat;
        }
      }
      //.news-without-img:nth-child(6n+4){
      //  position: relative;
      //  padding-top: 100px;
      //
      //  &:before {
      //    position: absolute;
      //    content: '';
      //    top: 10px;
      //    left: -43px;
      //    width: 130px;
      //    height: 120px;
      //    background: url('../../assets/img/list-icon5.svg') left/contain no-repeat;
      //  }
      //  &:after {
      //    position: absolute;
      //    content: '';
      //    top: 10px;
      //    left: -43px;
      //    width: 130px;
      //    height: 120px;
      //    background: url('../../assets/img/list-icon5.svg') left/contain no-repeat;
      //  }
      //}
      .news-without-img:nth-child(6n+3){
        position: relative;
        padding-top: 50px;

        &:before {
          position: absolute;
          content: '';
          top: -49px;
          right: -30px;
          width: 60px;
          height: 100px;
          background: url('../../assets/img/list-icon3.svg') right/contain no-repeat;
        }
      }
    }


    //&::before,
    //&::after {
    //  content: "";
    //  flex-basis: unset;
    //  width: 0;
    //  order: unset;
    //}
  }
  .news-block {
    height: 430px;
    width: 480px;
    padding: 36px 0 30px;
    margin: 0 15px;
    border-bottom: 1px solid #E0E0E0;

    &:nth-child(3n) {
      border-bottom: 0;
    }
    a {
      text-decoration: none;
    }
    .news-block-text {
      height: 105px;
      overflow: hidden;
    }
    .news-block-image {
      position: relative;
      width: 100%;
      height: 310px;
      div {
        position: absolute;
        padding: 0 15px;
        bottom: 0;
        transform: translate(0, -20px);
        h2 {
          display: inline;
          font-size: 20px;
          line-height: 32px;
          color: $black;
        }
      }
    }
    .news-block-without-image {
      width: 100%;
      position: relative;
      div {
        padding: 0 15px;
        h2 {
          display: inline;
          font-size: 20px;
          line-height: 32px;
          color: $black;
        }
      }
    }
    p {
      display: block;
      padding: 0 10px;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: $black;
    }
    .news-block-date {
      margin-top: 10px;
      color: $red;
    }
  }
}

#pagination-demo {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
  padding-left: 0;
  margin-top: 60px;

  li {
    margin: 0 15px;
    height: 30px;
    width: 30px;

    &.disabled {
      cursor: default;
    }

    &:first-child,
    &:last-child {
      width: unset;
    }

    a {
      display: block;
      text-align: center;
      color: $black;
      text-decoration: none;
    }

    span {
      display: block;
      text-align: center;
    }

    &.active {
      width: 30px;
    }

    &.active .current {
      height: 100%;
      background: $yellow;
      border-radius: 50%;
      margin: 0 auto;
    }
  }
}

/* news */

.news-title-block {
  height: 476px;
  h1 {
    text-align: center;
    position: absolute;
    padding-top: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .separator {
    background-color: $red;
  }
}

.date-block {
  width: 100%;
  height: 45px;
  position: relative;
  background: $red;
  margin-bottom: 60px;
  .date {
    font-size: 16px;
    line-height: 45px;
    color: $white;
  }
}

.news-content-text {
  margin: 7px 0;
}

.news-section {
  .news-section-image {
    display: block;
    width: 90%;
    margin: 30px auto 0;
    text-align: center;
  }
}

/* contacts */

.contacts-title-block {
  background: $red;
  height: 426px;

}

/* hr */

.hr-title-block {
  height: 476px;
  background: $green url(/assets/img/hr-image.png) no-repeat 970px -50px;
  h1 {
    padding-top: 50px;
    width: 575px;
  }
  .separator {
    background-color: $red;
  }
}

.hr-work-section {
  background-color: $red;
  .hr-work-blocks {
    padding: 80px 0;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    .hr-work-block {
      font-weight: 700;
      width: 33%;
      margin-bottom: 25px;
      .hr-work-block-img {
        margin-bottom: 10px;
      }
      .hr-work-block-title {
        p {
          margin: 3px 0;
          display: inline-block;
          font-size: 20px;
          line-height: 32px;
          background-color: $yellow;
        }
        color: $black;
      }
      .hr-work-block-text {
        width: 80%;
        font-size: 16px;
        line-height: 23px;
        color: $white;
      }
    }
  }
}

/* audit */

.audit-title-block {
  height: 390px;
  background: $blue url(/assets/img/audit-image.png) no-repeat right 230px top -60px;
  h1 {
    padding-top: 12px;
    width: 675px;
  }
  .separator {
    background-color: $yellow;
  }
}

.audit-deep-ocean-section {
  background-color: $white2;
  padding: 100px 0;
  img {
    width: 100%;

  }
}

/* services */

.services-title-block {
  height: 426px;
  background: $red url(/assets/img/services-img.png) no-repeat right 22% top -40px;
}

.services-blocks-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  padding: 100px 0;

  a {
    text-decoration: none;
  }

  .services-block {
    position: relative;
    width: 540px;
    height: 253px;
    margin-bottom: 30px;
    padding: 35px 30px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;

    &:hover h3 {
      color: $black;
      background: $white;
    }

    &:hover h4 {
      color: $black;
      background: $white;
    }

    .small-text {
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      margin: 0 0 18px;
    }

    .small-text2 {
      max-width: 70%;
      width: 100%;
      font-size: 13px;
      line-height: 20px;
      margin: 8px 0 0;
    }

    h3 {
      display:inline;
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      padding: 0;
      transition: all .1s ease-out;
    }

    h4 {
      display:inline;
      font-weight: 700;
      font-size: 26px;
      line-height: 35px;
      margin-top: 22px;
      padding: 0;
      transition: all .1s ease-out;
    }

    &.red {
      background: $red;
      color: $white;
    }
    &.yellow {
      background: $yellow;
      color: $white;
    }
    &.green {
      background: $green;
      color: $white;
    }
    &.blue {
      background: $blue;
      color: $white;
    }
    &.grey {
      background: $white2;
      color: $black;

      &:hover h3 {
        color: $white;
        background: $black;
      }
    }
    &.big {
      width: 100%;
      height: 155px;
      padding-top: 55px;
    }
    .background-image {
      position: absolute;

      &.img-1 {
        background: url("../img/audit-image.png") right/contain no-repeat;
        top: -10%;
        right: -3%;
        width: 100%;
        height: 140%;
      }
      &.img-2 {
        background: url("../img/packing-image.png") right/contain no-repeat;
        top: -20%;
        right: -12%;
        width: 100%;
        height: 145%;
      }
      &.img-3 {
        background: url("../img/neuro-image.png") right/contain no-repeat;
        top: 30%;
        right: -22%;
        width: 100%;
        height: 78%;
      }
      &.img-4 {
        background: url("../img/ttl-image.png") right/contain no-repeat;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
      &.img-5 {
        background: url("../img/positioning.png") right/contain no-repeat;
        top: -16%;
        right: 2%;
        width: 100%;
        height: 330%;
      }
      &.img-6 {
        background: url("../img/training-img.png") right/contain no-repeat;
        bottom: -16%;
        right: -17%;
        width: 100%;
        height: 100%;
      }
      &.img-7 {
        background: url("../img/hero-img.png") right/contain no-repeat;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

    }
  }
}

/* positioning  */

.positioning-title-block {
  height: 426px;
  background: $yellow url(/assets/img/positioning.png) no-repeat right 30px bottom -240px;
  h1 {
    padding-top: 12px;
    width: 520px;
    font-size: 50px;
    line-height: 61px;
  }
  .separator {
    background-color: $red;
  }
  .title-block-text {
    max-width: 700px;
    width: 100%;
  }
}

.positioning-cost-section {
  .cost-block {
    .cost-text {
      flex-basis: 80%;
      span {
        font-size: 22px;
      }
      .cost-text-separator {
        margin: 0 10px;
        width: 3px;
        height: 15px;
        background-color: $white;
        display: inline-block;
      }
    }
  }
}

.positioning-about-section {
  background-color: $white2;
  padding: 80px 0px;
  .positioning-title {
    display: inline-block;
    background: $red;
    color: $white;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 15px;
  }
  p {
    width: 95%;
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    color: $black;
    margin-bottom: 15px;
    margin-top: 15px;
    span {
      font-weight: 700;
    }
  }
  .positioning-italic {
    font-weight: 700;
    margin-top: 69px;
    margin-bottom: 26px;
  }
}

.positioning-theory-section {
  background: $grey;
  color: $white;
  padding: 100px 0;

  .positioning-7p {
    max-width: 825px;
    width: 100%;
    margin: 0 auto;
  }

  .positioning-text {
    margin-top: 80px;
  }

  .positioning-color-text {
    font-weight: 700;
    color: $white;
    &.red {
      background: $red;
    }
    &.yellow {
      background: $yellow;
    }
    &.green {
      background: $green;
    }
    &.blue {
      background: $blue;
    }

  }

  .marketing-mix {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;

    .marketing-mix-block {
      width: 255px;
      height: 200px;
      background: $white2;
      color: $black;
      font-weight: 700;
      text-align: center;

      .marketing-mix-icon {
        width: 55px;
        height: 55px;
        margin: 36px auto 20px;
        &.icon1 {
          background: url(../../assets/img/marketing-mix1.svg) center/contain no-repeat;
        }
        &.icon2 {
          background: url(../../assets/img/marketing-mix2.svg) center/contain no-repeat;
        }
        &.icon3 {
          background: url(../../assets/img/marketing-mix3.svg) center/contain no-repeat;
        }
        &.icon4 {
          background: url(../../assets/img/marketing-mix4.svg) center/contain no-repeat;
        }
        &.icon5 {
          background: url(../../assets/img/marketing-mix5.svg) center/contain no-repeat;
        }
        &.icon6 {
          background: url(../../assets/img/marketing-mix6.svg) center/contain no-repeat;
        }
        &.icon7 {
          background: url(../../assets/img/marketing-mix7.svg) center/contain no-repeat;
        }
      }
    }
  }
}

.positioning-theory2-section {
  background: $white2;
  padding: 100px 0;

  .positioning-title-blue {
    display: inline-block;
    color: $white;
    background: $blue;
    font-weight: 700;
    font-size: 20px;
    margin: 50px 0 15px;
  }
}

/* game */

.game-title-block {
  height: 476px;
  background: $yellow url(/assets/img/game-image.png) no-repeat 960px -10px;
  h1 {
    padding-top: 66px;
    width: 675px;
    font-size: 64px;
    line-height: 76px;
  }
  .separator {
    background-color: $white;
  }
}

.game-area-section {
  background-color: $grey;
  height: 830px;
  .game-area-section-bg {
    position: relative;
    height: 100%;
    width: 100%;
    bottom: 0;
    .game-area-image {
      position: absolute;
      width: 1700px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: 1700px) {
        width: 100%;
      }
    }
    .circle-block {
      padding: 2.5%;
      .interior-circle {
        p {
          padding-bottom: calc(45% - 7px);
          font-size: 20px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .circle-1 {
      top: 7%;
      left: 3%;
    }
    .circle-2 {
      top: 30%;
      left: 18%;
    }
    .circle-3 {
      top: 61%;
      left: 48%;
    }
    .circle-4 {
      top: 13%;
      left: 50%;
      padding: 3%;
      .interior-circle {
        padding-top: calc(50% - 50px);
        p {
          padding-bottom: calc(45% - 50px);
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    .circle-5 {
      top: 45%;
      left: 70%;
      .interior-circle {
        padding-top: calc(50% - 50px);
        p {
          padding-bottom: calc(45% - 50px);
          padding-left: 50px;
          padding-right: 40px;
        }
      }
    }
  }
}

/* automation */

.automation-title-block {
  height: 390px;
  background: $red url(/assets/img/hero-image.png) no-repeat right 0 top -60px;
  h1 {
    padding-top: 12px;
    width: 520px;
    font-size: 50px;
    line-height: 61px;
  }
  .title-block-text {
    color: $white
  }
  .separator {
    background-color: $yellow;
  }
}

.why-automation-section {
  position: relative;
  background-color: $white2;
  padding: 80px 0;

  .why-automation-text {
    max-width: 840px;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $black;
  }

  .quote {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .author {
      flex-basis: 80%;
    }
  }
}

.how-automation {
  .how-automation-section-blocks {
    padding: 80px 0 50px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .how-automation-section-block {
    width: 42%;
    padding-right: 8%;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 30px;
  }

  .how-automation-section-block-text {
    flex-basis: 100%;

    .how-automation-section-block-title {
      position: relative;
      font-size: 16px;
      line-height: 26px;
      color: $black;
      padding-left: 80px;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
      }
      &.automation1:before {
        background: url(../../assets/img/automation1.svg) center/contain no-repeat;
      }
      &.automation2:before {
        background: url(../../assets/img/automation2.svg) center/contain no-repeat;
      }
      &.automation3:before {
        background: url(../../assets/img/automation3.svg) center/contain no-repeat;
      }
      &.automation4:before {
        background: url(../../assets/img/automation4.svg) center/contain no-repeat;
      }
      &.automation5:before {
        background: url(../../assets/img/automation5.svg) center/contain no-repeat;
      }
    }
  }
}

/* neuromarketing */

.neuro-title-block {
  height: 390px;
  background: $yellow url(/assets/img/neuro-image.png) no-repeat right 0 top -50px;
  h1 {
    padding-top: 12px;
    width: 520px;
    font-size: 50px;
    line-height: 61px;
  }
  .separator {
    background-color: $red;
  }
}

.what-neuro-section {
  position: relative;
  background-color: $white2;
  padding: 80px 0;

  .why-neuro-text {
    max-width: 840px;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $black;
  }
}

.neuro-data-section-block-t-lg {
  color: $black;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-top: 120px;
}

.neuro-data-section-blocks {
  padding-top: 70px;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.neuro-data-section-block {
  width: 24%;
  padding-right: 1%;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  display: flex;
  flex-flow: row wrap;
}

.neuro-data-section-block-text {
  flex-basis: 100%;
  padding-right: 8px;
  ul.neuro-data-section-block-t {
    list-style-type: none;
    padding-left: 0;
  }
  ul.neuro-data-section-block-t > li {
    text-indent: 0;
  }
  ul.neuro-data-section-block-t > li:before {
    content: "- ";
    text-indent: 0;
  }
  .neuro-data-section-block-title {
    color: $black;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    display: inline-block;
    background-color: $yellow;
  }
  .neuro-data-section-block-more {
    font-weight: 700;
    font-size: 15px;
    line-height: 29px;
    text-decoration-line: underline;
    color: $yellow;
  }
}

/* packing */

.packing-title-block {
  height: 410px;
  background: $green url(/assets/img/packing-image.png) no-repeat right 210px top -100px;
  h1 {
    padding-top: 12px;
    width: 520px;
    font-size: 50px;
    line-height: 61px;
  }
  .separator {
    background-color: $yellow;
  }
}

.packing-area-section {
  background-color: $blue;
  height: 800px;
  overflow: hidden;
  .packing-area-section-bg {
    position: relative;
    height: 100%;
    width: 100%;
    bottom: 0;
    background-image: url(/assets/img/bubble.svg), url(/assets/img/bubble.svg), url(/assets/img/bubble.svg), url(/assets/img/bubble.svg), url(/assets/img/bubble.svg), url(/assets/img/bubble.svg), url(/assets/img/bubble.svg);
    background-repeat: no-repeat;
    background-position: 2% 16%, 8% 8%, 56% -2%, 55% 59%, 82% 23%, 87% 42%, 89% 39%;
    background-size: 4%, 7.5%, 5.5%, 6%, 4%, 6%, 3%;
    .packing-area-image {
      width: 1050px;
      position: absolute;
      bottom: -250px;
      left: -200px;
    }
    .packing-area-lg {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .packing-area-image-sm {
      width: 430px;
      position: absolute;
      bottom: -70px;
      right: -80px;
      -webkit-transform: scaleX(-1) rotate(20deg);
      -moz-transform: scaleX(-1) rotate(20deg);
      -o-transform: scaleX(-1) rotate(20deg);
      transform: scaleX(-1) rotate(20deg);
    }
    .packing-area-sm {
      padding-top: 40px;
      p {
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: $white;
        margin: 25px 0;
      }
    }
    .circle-block {
      padding: 3%;
      text-align: center;
      .interior-circle {
        p {
          font-size: 20px;
        }
      }
    }
    .circle-1 {
      top: 3%;
      left: 0;
      .interior-circle {
        padding-top: calc(50% - 35px);
        p {
          padding-bottom: calc(45% - 30px);
          padding-left: 50px;
          padding-right: 50px;
        }
      }
    }
    .circle-2 {
      top: 17%;
      left: 45%;
      .interior-circle {
        padding-top: calc(50% - 15px);
        p {
          padding-bottom: calc(45% - 5px);
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
    .circle-3 {
      top: -3%;
      right: 0;
      padding: 4%;
      .interior-circle {
        padding-top: calc(45% - 25px);
        p {
          padding-bottom: calc(40% - 15px);
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
    .circle-4 {
      top: 22.5%;
      left: 14.5%;
      .interior-circle {
        padding-top: calc(50% - 35px);
        p {
          padding-bottom: calc(45% - 30px);
          padding-left: 50px;
          padding-right: 50px;
        }
      }
    }
    .circle-5 {
      top: 40%;
      left: 65%;
      .interior-circle {
        padding-top: calc(50% - 15px);
        p {
          padding-bottom: calc(45% - 5px);
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
    .circle-6 {
      bottom: -7%;
      right: 10%;
      .interior-circle {
        padding-top: calc(39%);
        p {
          padding-bottom: calc(35%);
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
}

.packing-cases-section {
  background-color: $white2;
  font-size: 16px;
  line-height: 26px;
  color: $white;
  padding: 100px 0;
  position: relative;
  z-index: 3;

  .packing-cases-section-text {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: $black;
    text-align: center;
  }
  .packing-cases-section-block-bottom-text {
    color: $black;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
  }
  .packing-cases-section-blocks {
    padding-top: 100px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .packing-cases-section-block {
    width: 30%;
    padding-right: 3%;
    margin-bottom: 60px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
  }
  .packing-cases-section-block-text {
    flex-basis: 100%;
    .packing-cases-section-block-t {
      font-size: 16px;
      line-height: 26px;
      color: $black;
      margin-top: 14px;
    }
    .packing-cases-section-block-t-sm {
      font-size: 13px;
      line-height: 23px;
      color: $black;
    }
    .packing-cases-section-block-title {
      color: $white;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      display: inline-block;
      background-color: $green;
    }
    .packing-cases-section-block-more {
      font-weight: 700;
      font-size: 15px;
      line-height: 29px;
      text-decoration-line: underline;
      color: $yellow;
    }
  }
}

.why-packing-section {
  position: relative;
  background-color: $white;
  padding: 100px 0;

  img {
    position: absolute;
    bottom: -80px;
    right: 7%;
  }

  .why-packing-text {
    max-width: 840px;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $black;
  }
}

/* inner-audit */

.inner-audit-title-block {
  height: 390px;
  background: $white2 url(/assets/img/training-img.png) no-repeat right 0 top -30px;
  h1 {
    color: $yellow;
    padding-top: 12px;
    font-size: 50px;
    line-height: 61px;
  }
  .separator {
    background-color: $red;
  }
}

.inner-audit-section {
  background-color: $grey;
  padding: 100px 0;

  .inner-audit-text {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: $white;
  }
}

.audit-result-section {
  background-color: $white;
  font-size: 16px;
  line-height: 26px;
  color: $white;
  .audit-result-section-bg {
    position: relative;
    height: 100%;
    width: 100%;
    bottom: 0;
  }
  .audit-result-section-blocks {
    padding-top: 80px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .audit-result-section-block {
    width: 47%;
    padding-right: 3%;
    margin-bottom: 60px;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    .audit-result-section-block-number {
      flex-basis: 69px;
      display: -webkit-flex;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

    }
    .audit-result-section-block-text {
      flex-basis: calc(100% - 69px - 20px);
      padding-left: 20px;
      color: $black;
      .audit-result-section-block-title {
        margin: 2px 0;
        color: $black;
        font-size: 18px;
        font-weight: 700;
        display: inline;
        background-color: $yellow;
      }
    }
  }
}

.metodology-section {
  background-color: $grey;
  padding: 50px 0;
  .metodology-section-text {
    color: $white;
    margin: 10px 0;

    a {
      color: $white;

    }
  }
}

/* ttl */

.what-ttl-section {
  position: relative;
  background-color: $white;
  padding: 100px 0;

  img {
    position: absolute;
    bottom: -80px;
    right: 7%;
    z-index: 2;
  }

  .what-ttl-text {
    max-width: 840px;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $black;
  }
  .what-ttl-text-yellow {
    display: inline-block;
    margin-top: 100px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: $black;
    background-color: $yellow;
  }
}

.what-ttl-section-blocks {
  padding-top: 70px;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.what-ttl-section-block {
  width: 32%;
  padding-right: 1%;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  display: flex;
  flex-flow: row wrap;
}

.what-ttl-section-block-text {
  flex-basis: 100%;

  .what-ttl-section-block-title {
    position: relative;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    color: $black;
    padding-left: 80px;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
    }
    &.ttl1:before {
      background: url(../../assets/img/ttl1.svg) center/cover no-repeat;
    }
    &.ttl2:before {
      background: url(../../assets/img/ttl2.svg) center/cover no-repeat;
    }
    &.ttl3:before {
      background: url(../../assets/img/ttl3.svg) center/cover no-repeat;
    }
  }
  .what-ttl-section-block-subtext {
    padding-left: 80px;
  }
  .what-ttl-section-block-download {
    padding-left: 80px;
    margin-top: 10px;
    font-weight: 700;
    text-decoration: underline;
    font-size: 15px;
    cursor: pointer;
    color: #383838;

    &:hover {
      text-decoration: none;
    }
  }
}

.form-section {

  position: relative;
  overflow: hidden;

  .circle-1 {
    width: 180px;
    top: -40px;
    left: -90px;
    padding: 2%;
    .interior-circle {
      padding-top: 180px;
    }
  }

  .circle-2 {
    position: absolute;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    top: 130px;
    left: 70px;
    background: $yellow;
  }
  .circle-3 {
    position: absolute;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    top: 340px;
    left: 150px;
    background: $yellow;
  }
  .circle-4 {
    position: absolute;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    bottom: 60px;
    right: -60px;
    background: $green;
  }
  .circle-5 {
    width: 160px;
    bottom: 180px;
    right: 90px;
    padding: 2%;
    .interior-circle {
      padding-top: 160px;
    }
  }
  .circle-6 {
    width: 140px;
    bottom: -60px;
    right: 100px;
    padding: 3%;
    .interior-circle {
      padding-top: 140px;
    }
  }

  form {
    position: relative;
    max-width: 640px;
    width: 100%;
    margin: 100px auto;
    z-index: 99;
  }

  input[type="text"],
  input[type="tel"] {
    width: 100%;
    height: 44px;
    background: $white2;
    border: 0;
    margin-top: 5px;
    outline: 0;
    box-sizing: border-box;
    padding: 0 18px;
    font-size: 16px;
    line-height: 26px;
    color: $black;
  }
  input[type="checkbox"]{
    margin-top: 22px;
  }
  textarea {
    width: 100%;
    min-height: 150px;
    background: $white2;
    border: 0;
    outline: 0;
    margin-top: 5px;
    box-sizing: border-box;
    padding: 10px 18px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: $black;
  }
  label {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: $grey2;

    a {
      text-decoration: none;
      color: $green;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
    display: block;
    text-align: center;
    height: 44px;
    width: 240px;
    margin: 0 auto;
    border: 0;
    background: $green;
    color: $white;
    font-weight: 700;

    &:disabled {
      background: $grey2;
    }
  }
}

.ttl-title-block {
  height: 400px;
  background: $blue url(/assets/img/ttl-image.png) no-repeat right 150px top -100px;
  h1 {
    padding-top: 12px;
    width: 630px;
    font-size: 50px;
    line-height: 61px;
  }
  .separator {
    background-color: $yellow;
  }
}

.ttl-area-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .ttl-area-row {
    width: 100%;
    display: flex;
    height: 280px;
    .ttl-area-block {
      text-decoration: none;
      height: 100%;
      .ttl-area-block-title {
        height: 65px;
        p {
          font-size: 20px;
          line-height: 65px;
          font-weight: 700;
          color: $white;
          margin: 0 30px;
          white-space: nowrap;
          span {
            font-weight: 400;
          }
        }
      }
      .ttl-area-block-img {
        height: calc(100% - 65px);
        &.agu {
          background: url(/assets/img/ttl-area-block-img1.png) center center/cover no-repeat;
        }
        &.bank {
          background: url(/assets/img/ttl-area-block-img2.png) center center/cover no-repeat;
        }
        &.home {
          background: url(/assets/img/ttl-area-block-img3.png) center center/cover no-repeat;
        }
        &.finfort {
          background: url(/assets/img/ttl-area-block-img4.png) center center/cover no-repeat;
        }
        &.ummi {
          background: url(/assets/img/ttl-area-block-img5.png) center center/cover no-repeat;
        }
        &.socproject {
          background: url(/assets/img/ttl-area-block-img6.png) center center/cover no-repeat;
        }
        &.shoco {
          background: url(/assets/img/ttl-area-block-img7.png) center center/cover no-repeat;
        }
        &.municipalities {
          background: url(/assets/img/ttl-area-block-img8.png) center center/cover no-repeat;
        }
        &.hight {
          background: url(/assets/img/ttl-area-block-img9.png) center center/cover no-repeat;
        }
      }
    }
    .ttl-area-block1 {
      //flex-basis: 41.666%;
      width: 800px;
    }
    .ttl-area-block2 {
      //flex-basis: 24.21875%;
      width: 465px;
    }
    .ttl-area-block3 {
      //flex-basis: 34.11458%;
      width: 655px;
    }
  }
}

.gallery {
  display: flex;
  flex-flow: row wrap;
  flex: auto;
  background-color: $grey;
  justify-content: center;
  .gallery-block {
    //flex: 1 1 0;
    //max-width: 100%;
    //height: auto;
    height: 18.21vw;
    object-fit: cover;
  }
}

/* component footer */

footer {
  width: 100%;
  height: 308px;
  .container {
    height: 300px;
  }
  .footer-bg {
    height: 8px;
    width: 100%;
    background-color: $yellow;
  }
  .main-nav {
    padding-top: 60px;
    margin-bottom: 50px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    nav {
      width: 650px;
      margin-top: 16px;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -webkit-align-self: flex-end;
      align-self: flex-end;
      margin-bottom: 3px;
      margin-left: 15px;
      a {
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: $black;
        padding-bottom: 2px;
      }
    }
    .logo {
      text-decoration: none;
      width: 200px;
      height: 30px;
    }
  }
  .social {
    height: 100px;
    color: $black;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    .social-adress-block {
      .social-adress {
        margin-top: 0;
        margin-bottom: 32px;
      }
      .social-email {
        font-weight: 700;
        text-decoration: none;
        color: $black;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .social-info {
      display: flex;
      display: -webkit-flex;
      justify-content: space-around;
      -webkit-justify-content: space-around;
      -webkit-align-self: center;
      flex-direction: column;
      align-self: center;
      .social-info-text-mob {
        display: none;
      }
      p {
        align-self: flex-end;
        margin-bottom: 0;
        margin-top: 42px;
      }
      a {
        text-decoration: none;
        margin-right: 25px;
        img {
          margin-top: 4px;
          transition: all .15s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .social-blank {
        margin-top: 32px;
        float: right;
        text-align: right;

        a {
          text-decoration: underline;
          color: $black;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

/* portfolio asu */

.title-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
}

.title-block .portfolio-title {
  text-align: center;
  position: absolute;
  padding-top: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.title-bottom-text {
  position: absolute;
  font-size: 14px;
  line-height: 30px;
  color: $white;
  bottom: 16px;
}

.portfolio-content {
  margin: 80px 0;
  position: relative;
  p {
    margin-bottom: 30px;
  }
  .portfolio-content-img {

    display: block;
    margin: 80px auto;
    object-fit: cover;
    max-width: 100%;
  }
}

.target {
  background-color: $white2;
  .target-inner {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 50px 0;
    .target-inner-block {
      position: relative;
      flex: 1 1 0;
      .target-inner-block-title {
        margin: 0 0 24px;
        display: inline-block;
        color: $white;
        font-size: 20px;
        font-weight: 700;
      }
      .target-inner-block-text {
        margin: 0 30px 0 0;
        font-size: 16px;
        line-height: 26px;
      }
      .target-inner-block-site {
        position: absolute;
        bottom: 0;
        span {
          color: #11DA87;
          text-decoration: underline;
        }
      }
    }
  }
}

.total {
  background-color: $yellow;
  .total-inner {
    display: flex;
    display: -webkit-flex;
    padding: 45px 0;
    font-weight: 700;
    //align-items: center;
    .total-title {
      font-size: 32px;
      margin-right: 36px
    }
    .total-text {
      font-size: 14px;
      line-height: 30px
    }
  }
}

.asu-title-block {
  height: 422px;
  background: url(/assets/img/asu-main-img.jpg) center/cover no-repeat;
}

.asu-content {
  .content-block-image {
    margin: 80px 0;
    display: flex;
    display: -webkit-flex;
    width: 100%;
    .image-item {
      flex: 1 1 0;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .brochure {
    background: url(/assets/img/brochure-img.png) center/cover no-repeat;
    width: 70%;
    height: 58vw;
    margin: 20px auto;
  }
  .star, .cloud, .cloud-hearts {
    position: absolute;
  }
  .star {
    bottom: 35%;
    left: 17%;
  }
  .cloud {
    bottom: 17%;
    right: 20%;
  }
  .cloud-hearts {
    bottom: 25%;
    left: 30%;
  }
}

.why-asu {
  position: relative;
  .why-asu-first-block {
    img {
      width: 100%;
      height: auto;
    }
    padding: 80px 0 40px;
  }
  .why-asu-second-block {
    img {
      width: 100%;
      height: auto;
    }
    padding: 40px 0 80px;
  }
  .star1, .star2, .star3 {
    position: absolute;
  }
  .star1 {
    bottom: 20%;
    right: 7%;
  }
  .star2 {
    bottom: 0%;
    right: 16%;
  }
  .star3 {
    bottom: 47%;
    left: 15%;
  }
}

/* portfolio likeathome */

.likeathome-title-block {
  background: url(/assets/img/likeathome-title-img.jpg) center/cover no-repeat;
  object-fit: cover;
  .container .portfolio-title {
    top: 40%;
  }
  .title-bg {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.likeathome-content {
  overflow: hidden;
  &.portfolio-content {
    margin-bottom: 0;
  }
  .likeathome-content-img-3 {
    margin-bottom: -70px;
  }
}

/* portfolio eatpbank */

.eatpbank-title-block {
  background: url(/assets/img/eatpbank-title-img.jpg) center/cover no-repeat;
  object-fit: cover;
  .container .portfolio-title {
    top: 45%;
  }
  .title-bg {
    background-color: rgba(2, 66, 140, 0.8);
  }
}

.eatpbank-content {
  position: relative;
  background: url(/assets/img/eatpbank-content-bg-1.png), url(/assets/img/eatpbank-content-bg-2.png);
  background-repeat: no-repeat;
  background-position: 0px 1950px, 100% 2590px;
  .eatpbank-content-img-4 {
    vertical-align: top;
    display: inline-block;
    width: 598px;
    height: 420px;
    margin: 80px 10px;
  }
  .eatpbank-content-img-5 {
    display: inline-block;
    width: 365px;
    height: 517px;
    margin: 80px 10px;
  }
  .eatpbank-content-img-6 {
    margin-bottom: 0;
  }
}

/* portfolio ummy */

.ummy-title-block {
  background: url(/assets/img/ummy-title-img.jpg) center/cover no-repeat;
  object-fit: cover;
  .container .portfolio-title {
    top: 40%;
  }
  .title-bg {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.ummy-maket {
  .ummy-content-img-2 {
    max-width: 100%;
  }

  p {
    font-weight: 600;
    margin-bottom: 30px;
  }
  margin-bottom: 80px;
}

.ummy-content {
  .candies, .nipple {
    position: absolute;
  }
  .candies {
    top: 740px;
    right: 250px;
  }
  .nipple {
    top: 500px;
    left: 200px;
  }
}

.ummy-total {
  .total-inner {
    align-items: center;
  }
}

/* portfolio finfort */

.finfort-title-block {
  background: rgba(17, 87, 146, 0.80) center/cover no-repeat;
  background-position: 0px -140px;
  object-fit: cover;
  .container .portfolio-title {
    top: 40%;
  }
  .title-bg {
    background-color: rgba(0, 0, 0, 0.01);
  }
}

.finfort-content {
  background-color: $black;
  padding: 80px 0;
  margin: 0;
  .finfort-content-img-1 {
    margin-top: -75px;
  }
}

.finfort-content-2 {
  background: url(/assets/img/finfort-content-bg-img.jpg) center/cover no-repeat;
  height: 1000px;
  object-fit: cover;
  margin: 0;
  padding-bottom: 80px;
  .finfort-content-2-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(41, 124, 195, 0.9);
  }
  .finfort-note {
    display: flex;
    .finfort-content-img-2 {
      margin-right: 10px;
    }
  }
  p {
    padding-top: 80px;
  }
}

.webinar-slides {
  height: 1250px;
  overflow-x: auto;
  position: relative;
  .webinar-img {
    position: absolute;
  }
  .finfort-webinar-img-1 {
    right: 400px;
    top: 0;
  }
  .finfort-webinar-img-2 {
    left: 0;
    top: 450px;
  }
  .finfort-webinar-img-3 {
    left: 480px;
    top: 490px;
  }
  .finfort-webinar-img-4 {
    right: 500px;
    top: 530px;
  }
  .finfort-webinar-img-5 {
    right: 20px;
    top: 450px;
  }
  .finfort-webinar-img-6 {
    left: 120px;
    top: 840px;
    z-index: 1;
  }
  .finfort-webinar-img-7 {
    left: 480px;
    top: 880px;
  }
  .finfort-webinar-img-8 {
    left: 710px;
    top: 630px;
    z-index: 2;
  }
  .finfort-webinar-img-9 {
    right: 340px;
    top: 880px;
    z-index: 1;
  }
  .finfort-webinar-img-10 {
    right: 0px;
    top: 820px;
    z-index: 0;
  }
  .finfort-webinar-img-11 {
    left: 1940px;
    top: 560px;
  }
  .finfort-webinar-img-12 {
    left: 2240px;
    top: 760px;
    z-index: 1;
  }
  .finfort-webinar-img-13 {
    left: 2420px;
    top: 660px;
  }

}

.finfort-total {
  .total-inner {
    align-items: center;
  }
}

/* portfolio socproject */

.socproject-title-block {
  background: url(/assets/img/socproject-title-img.jpg) center/cover no-repeat;
  object-fit: cover;
  .container .portfolio-title {
    top: 40%;
  }
  .title-bg {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.socproject-content {
  overflow: hidden;
  &.portfolio-content {
    margin-bottom: 0;
  }
  .balloons {
    position: absolute;
    bottom: -150px;
    left: 0;
    z-index: -1;
  }
}

.socproject-gallery.gallery {
  margin-top: 40px;
  .gallery-block {
    height: 16.6666vw;

  }
}

.socproject-content-2 {
  background-color: $black;
  margin: 0;
  padding: 80px 0;
  .portfolio-content-img {
    margin: 0;
  }
  .socproject-content-img-2 {
    float: left;
    margin-right: 24px;
  }
  .socproject-content-img-3 {
    margin-bottom: 17px;
  }

}

.socproject-total {
  .total-inner {
    align-items: center;
  }
}

.socproject-content-3 {
  margin-bottom: 0;
}

.socproject-content-img-7 {
  width: 100%;
  height: 100%;
}

/* portfolio chocolate */

.chocolate-title-block {
  background: url(/assets/img/chocolate-title-img.jpg) center/cover no-repeat;
  object-fit: cover;
  .container .portfolio-title {
    top: 40%;
  }
  .title-bg {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.chocolate-content {
  overflow: hidden;
  &.portfolio-content {
    margin-bottom: 20px;
  }
}

.chocolate-title-note {
  margin-top: 80px;
  font-weight: 600;
}

.chocolate-content-2 {
  .chocolate-content-img-1 {
    margin-top: -80px;
  }
}

.chocolate-gallery {
  .gallery-block {
    height: 19.63vw;
  }
}

.carousel {
  width: 915px;
  margin: 80px auto;
  background: transparent;
  overflow: hidden;
  .carousel-control-next-icon, .carousel-control-prev-icon {
    width: 19px;
    height: 39px;
  }
  .carousel-control-next-icon {
    background-image: url('/assets/img/next-icon.svg');

  }
  .carousel-control-prev-icon {
    background-image: url('/assets/img/prev-icon.svg');
  }

  .carousel-indicators li {
    width: 15px;
    height: 15px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 50%;
  }
  .carousel-indicators .active {
    background-color: #FFD426;
  }
}

.staff {
  background: url(/assets/img/section-staff-bg.jpg) center/cover no-repeat;
  padding: 125px 0;
  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-left: calc(50% - 492px);
    margin-bottom: 30px;
  }
  .staff-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 984px;
    margin: 0 auto;
    .staff-item-img {
      align-self: center;
    }
  }
}

/* portfolio municipality */

.municipality-title-block {
  background: url(/assets/img/municipality-title-img.jpg) center/cover no-repeat;
  object-fit: cover;
  .container .portfolio-title {
    top: 40%;
  }
  .title-bg {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.municipality-content {
  margin-bottom: 0;
  .municipality-title-logo {
    font-weight: 600;
  }
  .municipality-content-img-1 {
    margin-top: 20px;
  }
  .municipality-content-img-2 {
    margin: 0;
  }
  .municipality-content-img-3 {
    margin-top: 0;
  }
  .municipality-content-img-4 {
    margin-bottom: 0;
  }
  .catalog {
    .portfolio-content-img {
      margin: 5px auto;
    }
  }
}

/* portfolio salesnight */

.salesnight-title-block {
  background: url(/assets/img/salesnight-title-img.jpg) center/cover no-repeat;
  object-fit: cover;
  .salesnight-title-img-bg {
    width: 344px;
    height: 357px;
    top: inherit;
    bottom: 0;
    left: 20%;
    z-index: 4;
  }
  .container .portfolio-title {
    z-index: 5;
    top: 40%;
  }
  .title-bg {
    background-color: rgba(11, 7, 61, 0.8);
  }
}

.salesnight-content {
  .container {
    ul {
      padding-left: 20px;

    }
  }
}

.salesnight-target {
  .target-inner .target-inner-block .target-inner-block-text {
    margin-right: 70px;
  }
}

.salesnight-target-list {
  padding-left: 20px;
}

.salesnight-results {
  background-color: $yellow;
  width: 100%;
  padding: 80px 0;
  position: relative;
  .row-text {
    font-weight: 600;
    font-size: 32px;
    line-height: 75px;
    margin-bottom: 55px;
  }
  .white-bg {
    display: inline-block;
    font-size: 15px;
    line-height: 23px;
    font-weight: 600;
    padding-left: 20px;
  }
  ul {
    margin-bottom: 80px;
  }
  .bottom-text {
    margin-bottom: 50px;
    span {
      font-size: 26px;
      line-height: 23px;
      font-weight: 600;
    }
  }
  img {
    position: absolute;
    bottom: 80px;
    right: 20%;
  }
  .salesnight-results-blocks {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .salesnight-results-block {
      width: 255px;
      height: 160px;
      text-align: center;
      .salesnight-results-block-title {
        font-size: 26px;
        line-height: 23px;
        font-weight: 600;
        margin-bottom: 40px;
        position: relative;
        &:after {
          position: absolute;
          bottom: -20px;
          left: 0;
          content: '';
          width: 255px;
          height: 1px;
          background-color: $white;
        }
      }
      .salesnight-results-block-text {
        font-size: 16px;
        line-height: 26px;
        span {
          font-weight: 600;
        }
      }
    }
  }

}

.salesnight-news {
  background-color: $grey;
  width: 100%;
  padding: 40px 0 40px;
  text-align: center;
  img {
    width: 695px;
    height: 891px;
  }
}

.salesnight-gallery {
  .gallery-block {
    height: 16.666667vw;
  }
}

.modal {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .overlay {
    overflow: auto;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 99;
    opacity: 0;
    filter: alpha(opacity=0); /* For IE8 and earlier */
  }

  .overlayVisible {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.6;
    filter: alpha(opacity=60); /* For IE8 and earlier */
    cursor: pointer;
  }

  .overlayVisible::before {
    content: 'X';
    font-family: sans-serif;
    position: fixed;
    width: 100%;
    text-align: center;
    top: 42%;
    color: #626262;
    font-size: 4em;
  }

  .scrollable-section {
    min-height: 100%;
  }

  .toggle-person {
    position: fixed;
    width: 90%;
    box-sizing: border-box;
    left: 5%;
    top: 5vh;
    max-height: 95vh;
    z-index: 9999;
    overflow-y: auto;
  }

  .toggleHidden {
    position: fixed;
    pointer-events: none;
    opacity: 0;
    filter: alpha(opacity=0); /* For IE8 and earlier */

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .toggleVisible {
    opacity: 1;
    filter: alpha(opacity=100); /* For IE8 and earlier */
    pointer-events: auto;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .closeBox {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 14px;
    height: 14px;
    background: url(/assets/img/close-btn.svg);
    background-repeat: no-repeat;
    border: none;
    outline: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.news-main-block {
  padding: 50px 0 0;
  background: $blue url("../../assets/img/ship-main.png") no-repeat left 15% bottom -35px;

  h2 {
    font-size: 30px;
    color: $red;
    margin: 0;
  }
  .news-main-text {
    margin-top: 15px;
  }

  button {
    height: 48px;
    width: 190px;
    background: transparent;
    border: 2px solid $red;
    color: $red;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    outline: 0;
    transition: .1s all ease-in-out;
    cursor: pointer;
    margin-top: 30px;

    &:hover {
      background: $red;
      color: $white;
    }
  }
  .news-main-content {
    display: flex;
    flex-direction: row;
  }
  .news-main-left {
    flex-basis: 50%;
    padding-top: 65px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .news-main-right {
    position: relative;
    width: 557px;
    height: 760px;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    align-content: center;
    box-sizing: border-box;
    font-family: 'Anonymous Pro', sans-serif;
    background: url("../../assets/img/list.svg") top/cover no-repeat;
    padding: 130px 60px 30px;

    .news-block:nth-child(n+4) {
     display: none;
    }

    &:before {
      position: absolute;
      content: '';
      top: 62px;
      left: 19px;
      width: 180px;
      height: 80px;
      background: url('../../assets/img/list-icon1.svg') center/contain no-repeat;
    }
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      right: 23px;
      width: 100px;
      height: 100px;
      background: url('../../assets/img/list-icon4.svg') right/contain no-repeat;
    }

    a {
      text-decoration: none;
    }
    h2 {
      font-size: 20px;
      line-height: 24px;

      &:hover {
        text-decoration: underline;
      }
    }
    .news-block-text {
      font-size: 18px;
      line-height: 24px;
      height: unset;
      max-height: 100px;
      overflow: hidden;
      padding: 0;
      margin-top: 10px;
    }
    .news-block {
      height: unset;
      width: unset;
      padding: 25px 0 0;
      border-bottom: 0;
    }
    .news-block-date {
      display: block;
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      color: $black;
    }

    .news-without-img:nth-child(6n+3){
      position: relative;
      padding-top: 50px;

      &:before {
        position: absolute;
        content: '';
        top: -65px;
        right: -38px;
        width: 70px;
        height: 110px;
        background: url('../../assets/img/list-icon3.svg') right/contain no-repeat;
      }
    }
  }

}

#map {
  width: 100%;
  height: 500px;
  margin-top: 4px;
}

.cookies {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 25px;
  width: 100%;
  height: 110px;
  background: $white2  url(/assets/img/dot-black.png) -100px 0;

  .cookie-img {
    height: 126px;
    width: 152px;
    margin-top: 20px;
    background: url(/assets/img/cookie1.png) center/contain no-repeat;
  }
  .cookie-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    p {
      max-width: 450px;
    }
  }
  .cookie-button {
    height: 45px;
    width: 160px;
    background: $green;
    border: 0;
    color: $white;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    outline: 0;
    cursor: pointer;
  }
}

@keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -3715px;
  }

}

@-moz-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -3715px;
  }

}

@-webkit-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -3715px;
  }

}

@-ms-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -3715px;
  }

}

@-o-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -3715px;
  }

}

/* Media queries  */
@import "/app/assets/css/media/media.scss";
